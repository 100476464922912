import { Input, Form, Button, Col, Row, Switch, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IStore } from "../../../../store";
import { setStatusNull } from "../../../../store/actions";
import { editarOperador } from "../../../../store/operadorAction";
import { IStatusList, ITalhaoTablet } from "../../../../store/types";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { IFazendaList } from "../../../../reducers/FazendaReducer";
import { Option } from "antd/lib/mentions";
import { editarTalhao } from "../../../../store/talhaoActions";

interface EditarTalhaoProps {
    status: IStatusList;
    setModal?: any;
    cont?: any;
    editarTalhao: Function;
    setStatusNull: any;
    talhao: ITalhaoTablet;
    fazendas: IFazendaList;
}
function EditarTalhao(props: EditarTalhaoProps) {
    const { editarTalhao, status, setModal, setStatusNull, fazendas } = props;
    const [validoNome, setValidoNome] = useState(true);
    const [validoTalhao, setValidoTalhao] = useState(true);

    const handleDisable = useCallback(() => {
        if (validoNome === true && validoTalhao === true) {
            return false;
        }
        return true;
    }, [validoTalhao, validoNome]);

    useEffect(() => {
        if (status.talhao_tablet?.edit?.success) {
            props.setModal(false);
            setStatusNull("edit", "talhao_tablet");
        }
    });

    return (
        <>
            <Form
                onChange={() => {}}
                initialValues={props.talhao}
                layout="vertical"
                onFinish={(v: any) => {
                    let obj = {
                        id_talhao: v.id_talhao,
                        nome_talhao: v.nome_talhao,
                        fazenda_temp: v.fazenda_temp,
                        coordenadas: props.talhao.coordenadas,
                        area: props.talhao.area,
                        ref: props.talhao.ref,
                        cod_erp: props.talhao.cod_erp,
                    };
                    editarTalhao(obj);
                    props.setModal(false);
                }}
            >
                <Form.Item name="id_talhao" style={{ display: "none" }}>
                    <Input type="hidden" name="id_talhao"></Input>
                </Form.Item>{" "}
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoNome(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidoNome(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Nome:"
                            name="nome_talhao"
                        >
                            <Input placeholder="Digite o nome do talhao" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione uma Fazenda",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidoTalhao(false),
                                                Promise.reject()
                                            );
                                        }

                                        setValidoTalhao(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                            label="Fazenda:"
                            name="fazenda_temp"
                        >
                            <Select>
                                {fazendas.map(f => (
                                    <Option
                                        key={f.id_fazenda}
                                        value={f.id_fazenda}
                                    >
                                        {f.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                        loading={status.talhao_tablet?.edit?.loading}
                    >
                        Salvar talhao
                    </Button>
                </div>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        status: store.loader,
        fazendas: store.fazendas,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            editarTalhao,
            setStatusNull,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarTalhao);
